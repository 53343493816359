import React, { useState } from 'react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import 'swiper/css/bundle';
import 'swiper/css';
import './index.css';

SwiperCore.use([Navigation, Autoplay]);

function Card(props) {
  const profileImage = getImage(props.cardData.profileImage.localFile);
  const companyLogo = getImage(props.cardData.companyLogo.localFile);

  return (
    <div
      className="card-medium mx-3 flex max-w-xs flex-col justify-between rounded-md border bg-crio-neutral-250  lg:max-w-full xl:mx-3"
      id="slideV3"
    >
      <div className="p-4">
        <p className="testimonial-card-text text-sm leading-6 text-crio-neutral-75 md:leading-7 ">
          "{props.cardData.testimonialText}"
        </p>
        <h6 className="pt-2 text-crio-yellow-500">{props.cardData.name}</h6>
      </div>
      <div style={{ maxHeight: '100%', height: '150px' }}>
        <GatsbyImage
          placeholder="none"
          loading="lazy"
          image={profileImage}
          alt={'profile'}
          style={{ maxHeight: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />

        {/* <Image
          style={{ maxHeight: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
          filename={`strapi/${props.cardData.profileImage.split('/')[4]}`}
        ></Image> */}
      </div>

      <div className="mx-4 my-2 mb-4 rounded-md bg-crio-neutral-300 py-2 px-1">
        <p className="text-center text-xs font-bold text-crio-neutral-500">
          {props.cardData.name} cracked a career at
        </p>
        <div className="mt-1 mb-1">
          <div style={{ maxHeight: '100%', height: '50px' }}>
            <GatsbyImage
              loading="lazy"
              placeholder="none"
              image={companyLogo}
              alt={'company'}
              style={{ maxHeight: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />

            {/* 
            <Image
              style={{ maxHeight: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
              filename={`strapi/${props.cardData.companyLogo.split('/')[4]}`}
            ></Image> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function CarouselV3(props) {
  const [swiperObject, setSwiperObject] = useState(null);
  const settings = {
    spaceBetween: 0,
    slidesPerView: props.isMobile ? 1.5 : 3.5,
    centeredSlides: true,
    id: 'carouselV3',
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    navigation: props.isMobile ? false : true,
    speed: 800,
    preloadImages: true,
  };

  return (
    <div className="carousel3-wrapper">
      <Swiper {...settings} onSwiper={setSwiperObject} className="carousel-v3">
        {props.data.map((val, idx) => {
          return (
            <SwiperSlide key={`hiringSlide-${idx}`}>
              <div
                onMouseOver={() => {
                  if (swiperObject) swiperObject.autoplay.stop();
                }}
                onMouseOut={() => {
                  if (swiperObject) swiperObject.autoplay.start();
                }}
              >
                <Card cardData={val} />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
