import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import VideoPopup from '../Fellowship/VideoPopup/index';
import * as styles from './CarouselV1.module.scss';
import db from '../../data/db.json';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import './index.css';
import { useDialog } from '@components/v5/Dialog/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { LEAD_GEN_HERO } from '../../constants/LeadGenerationConstants/index';

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);
const youTubeIdFromLink = (url) =>
  url.match(
    /(?:https?:\/\/)?(?:www\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&]+)/,
  )[1];
function Card(props) {
  const entry = db.strapi_metadata.programs.find(
    (ele) => ele.PrimaryProgramID == 'FREE_TRIAL',
  );
  const companyLogo = getImage(props.data.companyLogo?.localFile);
  const image = getImage(props.data.thumbnail?.localFile);

  return (
    <>
      <div className="mx-auto grid w-9/12 max-w-screen-2xl grid-cols-12 pb-8 xl:mx-auto">
        <div className="col-span-6 flex flex-col justify-around">
          <div className="flex flex-col">
            <div className="mb-4 flex items-center">
              <h1
                className={`block text-3xl font-semibold leading-10 text-crio-green-900 ${styles.customFont}`}
              >
                Meet{' '}
                <span className="text-crio-green-600">{props.data.name}</span>
              </h1>
              <a href={props.data.linkedin} target="_blank" rel="noreferrer">
                <div className="ml-4 h-7 w-7 rounded-full ">
                  <StaticImage
                    loading="lazy"
                    src="../../images/Linkedin.png"
                    placeholder="blurred"
                    alt="Linkedin"
                  />
                  {/* <Image filename="Linkedin.png"></Image> */}
                </div>
              </a>
            </div>
            <div className="w-1/2 rounded-md bg-crio-neutral-100 pt-2">
              <p className="text-center text-xs font-bold text-crio-neutral-500">
                {props.data.name} cracked a career at
              </p>
              <div style={{ maxHeight: '100%', height: '60px' }}>
                <GatsbyImage
                  loading="lazy"
                  image={companyLogo}
                  alt={'company'}
                  style={{ maxHeight: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </div>
            </div>
          </div>
          <div>
            <p className="pt-4 pr-5">"{props.data.quote}"</p>
          </div>
          <div>
            <div className="mt-8 flex items-center text-center">
              {' '}
              <LeadGenerationButton buttonLocation={LEAD_GEN_HERO} />
            </div>
          </div>
        </div>
        <div className="col-span-6 flex  max-w-lg items-center justify-center">
          <div className="">
            <div className="relative cursor-pointer overflow-hidden rounded-lg">
              <div
                className="iframe-image  aspect-[4/3] rounded-lg"
                onClick={() => {
                  // props.setVideo(props.data.iFrame);
                  // props.setOpen();
                  props.modalHandler(props.data.iFrame);
                }}
              >
                <FontAwesomeIcon
                  className="inset-center absolute text-white transition-all hover:opacity-60"
                  icon={['fas', 'play']}
                  size="2x"
                  style={{ zIndex: '99' }}
                />

                <GatsbyImage
                  loading="lazy"
                  image={image}
                  alt={'company'}
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function MobileCard(props) {
  const companyLogo = getImage(props.data.companyLogo?.localFile);
  const image = getImage(props.data.thumbnail?.localFile);

  return (
    <>
      <div className="mx-auto mt-0 w-10/12 rounded-md bg-crio-neutral-250 p-6">
        <div>
          <div className="relative cursor-pointer overflow-hidden rounded-lg">
            <div
              className="iframe-image  rounded-lg"
              onClick={() => {
                // props.setVideo(props.data.iFrame);
                // props.setOpen();
                props.modalHandler(props.data.iFrame);
              }}
            >
              <FontAwesomeIcon
                className="inset-center absolute text-white transition-all hover:opacity-60"
                icon={['fas', 'play']}
                size="2x"
                style={{ zIndex: '99' }}
              />
              <GatsbyImage
                loading="lazy"
                image={image}
                alt={'company'}
                style={{ width: '100%', transform: 'scale(1.4)' }}
              />
            </div>
          </div>
        </div>
        <div className="mobile-text-card flex flex-col justify-between">
          <div className="flex items-center">
            <h1 className="text-2xl font-semibold leading-10 text-crio-green-900">
              Meet{' '}
              <span className="text-crio-green-600">{props.data.name}</span>
            </h1>
            <a href={props.data.linkedin} target="_blank" rel="noreferrer">
              <div className="mt-1 ml-2 h-5 w-5 rounded-full ">
                <StaticImage
                  loading="lazy"
                  src="../../images/Linkedin.png"
                  placeholder="blurred"
                  alt="Linkedin"
                />
                {/* <Image filename="Linkedin.png"></Image> */}
              </div>
            </a>
          </div>
          <div>
            <p className="pr-4 text-crio-neutral-800">{props.data.quote}</p>
          </div>
          <div className="mt-4 rounded-md bg-crio-neutral-300 pr-4">
            <p
              className="pl-2 pt-2 text-center font-bold text-crio-neutral-500"
              style={{ fontSize: '12px' }}
            >
              {props.data.name} cracked a career at
            </p>
            <div style={{ maxHeight: '100%', height: '60px' }}>
              <GatsbyImage
                loading="lazy"
                image={companyLogo}
                alt={'company'}
                style={{ maxHeight: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />

              {/* <Image
                style={{ maxHeight: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
                filename={`strapi/${props.data.companyLogo.split('/')[4]}`}
              ></Image> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default function CarouselV1(props) {
  const isMobile = props.isMobile;
  const settings = {
    /*   effect: "fade",
    fadeEffect: { crossFade: true }, */
    slidesPerView: false ? 1 : 1,
    id: 'heroCarousel',
    loop: true,
    grabCursor: true,
    noSwiping: false,
    preloadImages: true,
    navigation: isMobile ? false : true,
    // pagination: {
    //   el: '.swiper-pagination',
    //   type: 'bullets',
    // },
  };
  const [video, setVideo] = useState('');
  const [openVideo, setOpenVideo] = useState(false);

  const [openDialog, closeDialog] = useDialog();

  const modalHandler = (video) => {
    openDialog({
      children: (
        <VideoPopup video={video} closeDialog={closeDialog}></VideoPopup>
      ),
    });
  };

  return (
    <>
      {/* <VideoPopup
        isOpen={openVideo}
        video={video}
        setOpen={(val) => setOpenVideo(val)}
      ></VideoPopup> */}
      <Swiper {...settings} className="carousel-v1 ">
        {isMobile
          ? props.data.map((val, idx) => {
              return (
                <SwiperSlide key={`heroSlide-${idx}`} className={'pb-8'}>
                  <MobileCard
                    data={val}
                    // setVideo={(video) => {
                    //   setVideo(video);
                    // }}
                    // setOpen={() => {
                    //   setOpenVideo(!openVideo);
                    // }}
                    modalHandler={modalHandler}
                  />
                </SwiperSlide>
              );
            })
          : props.data.map((val, idx) => {
              return (
                <SwiperSlide
                  /* style={{ listStyle: "none" }} */
                  /* tag="li" */
                  key={`heroSlide-${idx}`}
                >
                  <Card
                    data={val}
                    // setVideo={(video) => {
                    //   setVideo(video);
                    // }}
                    // setOpen={() => {
                    //   setOpenVideo(!openVideo);
                    // }}
                    modalHandler={modalHandler}
                  />
                </SwiperSlide>
              );
            })}
      </Swiper>
    </>
  );
}
