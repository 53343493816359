import db from '../data/db.json';

export default function useCrioStat() {
  const {
    'community-numbers': communityNumbers,
    averageCTCRange,
    'placement-stats': placementStats,
    'success-stories': successStory,
  } = db.strapi_metadata.crioStats;

  return { communityNumbers, averageCTCRange, placementStats, successStory };
}
