import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function VideoPopup(props) {
  return (
    <div className="flex flex-col bg-black p-4 pt-2">
      <div className="h-8 self-end">
        <FontAwesomeIcon
          className="cursor-pointer text-white transition-all hover:opacity-50"
          icon={['fal', 'times']}
          size="2x"
          onClick={() => {
            props.closeDialog();
          }}
        />
      </div>
      <iframe
        loading="lazy"
        frameBorder="0"
        allowFullScreen="allowFullScreen"
        src={
          props.video +
          '?autoplay=1&rel=0&enablejsapi=1&origin=https%3A%2F%2Fcrio.do'
        }
        className="h-[200px] w-screen md:h-[50vh] md:w-[800px]"
      />
    </div>
  );
}
