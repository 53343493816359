import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function CrioExperienceTile(props) {
  const profileImage = getImage(props.data.profileImage.localFile);

  return (
    <>
      {props.isMobile ? (
        <div className="flex h-full flex-col justify-between rounded-lg bg-crio-green-600 p-4">
          <div>
            <h5 className="leading-snug text-crio-yellow-500">
              {props.data.blogTitle}
            </h5>
          </div>
          <div>
            <hr className="my-2 md:bg-crio-green-900" />
            <p className="py-1 font-bold text-white">{props.data.name}</p>
            <a
              style={{ color: '#E9C37A' }}
              className="py-1 text-crio-yellow-500 underline"
              href={props.data.blogLink}
              target={'_blank'}
              rel="noreferrer"
            >
              Read the full story
            </a>
          </div>
        </div>
      ) : (
        <div className="h-full rounded-lg bg-crio-green-600 p-5 pb-0 ">
          <div className="flex justify-start">
            <div className="hidden md:block lg:mr-4">
              <div
                style={{ maxHeight: '100%', height: '120px' }}
                className=" mr-4 w-28 lg:ml-4"
              >
                {' '}
                <GatsbyImage
                  placeholder="none"
                  loading="lazy"
                  image={profileImage}
                  alt={'profile'}
                  style={{ maxHeight: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
                {/* <Image
                  style={{ maxHeight: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
                  filename={`strapi/${props.data.profileImage.split('/')[4]}`}
                ></Image> */}
              </div>
            </div>
            <div className="flex flex-1 flex-col justify-around">
              <div>
                <h4 className=" text-medium text-base leading-5 text-crio-yellow-500 md:text-2xl md:leading-8">
                  {props.data.blogTitle}
                </h4>
                <p className="hidden text-base text-white md:mt-1 md:block md:pr-4 md:leading-7">
                  {props.data.blogExcerpt}
                </p>
              </div>
              <div>
                <hr className="my-2 md:border-crio-green-900 md:bg-crio-green-900" />
                <a
                  className="hidden py-1 text-crio-yellow-500 underline md:block lg:float-right "
                  href={props.data.blogLink}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Read the full story
                </a>
                <h5 className="text-normal py-1 text-sm  leading-7 text-white md:text-base ">
                  {props.data.name}
                </h5>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
