import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import reviews from '../../../seo/jsonld/reviews.json';

function SEO({
  description,
  lang,
  meta = [],
  title,
  postTitle,
  children,
  canonicalUrl,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      link={[
        ...(canonicalUrl
          ? [
              { rel: 'canonical', href: canonicalUrl },
              { rel: 'alternate', hreflang: 'en', href: canonicalUrl },
            ]
          : []),
      ]}
      htmlAttributes={{
        lang,
      }}
      title={title || site.siteMetadata.title}
      // titleTemplate={`%s | ${site.siteMetadata.title} ${
      //   postTitle ? ` | ${postTitle}` : ''
      // }`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {children}
      <script type="application/ld+json">{JSON.stringify(reviews)}</script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
