import Button from '@components/Button/index';
import CarouselV1 from '@components/CarouselV1';
import CarouselV2 from '@components/CarouselV2';
import CarouselV3 from '@components/CarouselV3';
import SEO from '@components/Common/SEO/index';
import CrioExperienceTile from '@components/CrioExperienceTile/index';
import useResizer from '@components/extra/useResizer';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import * as styles from './Testimonials.module.scss';
import { graphql } from 'gatsby';
import FooterV2 from '@components/FooterV2/index';
import Navigation from '@components/Navigation';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import {
  LEAD_GEN_HERO,
  LEAD_GEN_HIRING_SECTION,
  LEAD_GEN_TEXTUAL_SECTION,
} from '../../constants/LeadGenerationConstants/index';
import { useWebEngage } from '@src/hooks/useWebEngage';
import useCrioStat from '@src/hooks/useCrioStats';

export default function Testimonials(props) {
  const entry = props.data.strapiPrograms;
  const isMobile = useResizer();
  const { captureUserClickEvent } = useWebEngage();
  const { successStory } = useCrioStat();
  // console.log(successStory);

  const statsData = successStory.map((stat) => ({
    stat: stat.content,
    caption: stat.label,
  }));

  // const statsData = [
  //   {
  //     stat: '5k+',
  //     caption:
  //       'curious developers chose to <strong>#learnbydoing</strong> with Crio',
  //   },
  //   { stat: '500+', caption: 'top companies trust Crio talent' },
  //   {
  //     stat: '20k+',
  //     caption: "aspiring developers in the Crio's Community",
  //   },
  // ];

  /* console.log(companiesData) */

  const graduatesData = props.data.allStrapiGraduates.nodes.reduce(
    (result, obj) => {
      if (
        obj.showInPlacementsSection &&
        obj.company &&
        obj.company.Logo &&
        obj.Photo
      ) {
        result.push({
          name: obj.Name,
          companyLogo: obj.company.Logo,
          profileImage: obj.Photo,
        });
      }
      return result;
    },
    [],
  );

  const companiesData = props.data.allStrapiCompanies.nodes.reduce(
    (result, obj) => {
      result.push({
        id: obj.strapiId,
        name: obj.Name,
        url: obj.Logo,
      });
      return result;
    },
    [],
  );
  const allYoutubeThumbnail = props.data.allYoutubeThumbnail.nodes;

  let testimonialQuotesData =
    props.data.allStrapiTestimonialWithQuotes.nodes.reduce((result, obj) => {
      result.push({
        name: obj.graduate.Name,
        linkedin: obj.LinkedInLink,
        companyLogo: companiesData.find((val) => val.id == obj.graduate.company)
          ?.url,
        iFrame: obj.YoutubeVideoLink,
        quote: obj.Quote,
        thumbnail: obj.thumbnail,
      });
      return result;
    }, []);

  testimonialQuotesData = testimonialQuotesData.filter((el) => el.thumbnail);

  const collegesData = props.data.allStrapiColleges.nodes.reduce(
    (result, obj) => {
      result.push({
        id: obj.strapiId,
        name: obj.Name,
        url: obj.CollegeLogo,
      });
      return result;
    },
    [],
  );

  /* console.log(graduatesData); */
  const textualTestimonialsData =
    props.data.allStrapiTextualTestimonials.nodes.reduce((result, obj) => {
      if (obj.graduate && obj.graduate.company) {
        result.push({
          name: obj.graduate.Name,
          profileImage: obj.graduate.PhotoVersionTwo,
          companyLogo: companiesData.find(
            (val) => val.id == obj.graduate.company,
          ).url,
          testimonialText: obj.TestimonialText,
        });
      }
      return result;
    }, []);
  // console.log(textualTestimonialsData);
  const blogTestimonialsData =
    props.data.allStrapiBlogTestimonials.nodes.reduce((result, obj) => {
      if (obj.graduate.company || obj.graduate.college) {
        result.push({
          name: obj.graduate.Name,
          profileImage: obj.graduate.PhotoVersionTwo,
          blogTitle: obj.BlogName,
          blogExcerpt: obj.BlogExcerpt,
          blogLink: obj.BlogLink,
          companyLogo: obj.graduate.company
            ? companiesData.find((val) => val.id == obj.graduate.company).url
            : collegesData.find((val) => val.id == obj.graduate.college).url,
        });
      }
      return result;
    }, []);

  return (
    <>
      <SEO
        canonicalUrl={'https://crio.do/testimonials'}
        title="Crio.Do Review From Developers"
        description="Honest Crio feedback reviews from past developers. Find out why they love to Learn by Doing with us."
      ></SEO>
      <Navigation />
      <div className="bg-crio-neutral-75">
        <section className="pt-32 pb-8">
          <div className="container">
            <h2
              className={`mb-12 px-4 text-crio-green-700 md:mx-auto md:p-0 md:px-8  md:text-center md:text-crio-green-900 ${styles.customFont}`}
            >
              <span className="border-b-4 border-crio-green-700 md:border-crio-green-900">
                Learning
              </span>{' '}
              at Crio is beyond just technical skills
            </h2>
          </div>
          <CarouselV1 data={testimonialQuotesData} isMobile={isMobile} />
          {isMobile ? (
            <div className="container  mx-auto">
              <div className="mt-8  flex items-center justify-center text-center">
                {' '}
                <LeadGenerationButton buttonLocation={LEAD_GEN_HERO} />
              </div>
            </div>
          ) : (
            ''
          )}
        </section>
      </div>
      <div className="bg-crio-neutral-100">
        <section className="container bg-crio-neutral-100">
          <div className="grid grid-cols-1 justify-items-center lg:grid-cols-3">
            {statsData.map((val, idx) => (
              <div className="my-12 " key={idx}>
                <h1
                  className={`mb-4 text-center text-7xl text-crio-green-600 ${styles.customFont}`}
                >
                  {val.stat}
                </h1>
                <p className="crio-neutral-900 text-center text-xl leading-6 lg:w-60">
                  {ReactHtmlParser(val.caption)}
                </p>
              </div>
            ))}
          </div>
        </section>
      </div>
      <section
        className={`mb-0 bg-crio-neutral-75 pt-16 pb-12  ${styles.customFont}`}
      >
        <div className="container">
          <h1 className="mb-12  w-11/12 text-crio-green-700 md:mx-auto md:p-0 md:text-center md:text-crio-green-900">
            Crio developers have cracked{' '}
            <span className="border-b-4 border-crio-green-700 md:border-crio-green-900">
              careers
            </span>{' '}
            in
          </h1>{' '}
        </div>
        <CarouselV2
          isMobile={isMobile}
          data={graduatesData.slice(0, graduatesData.length / 2)}
        />
        <br />
        <CarouselV2
          isMobile={isMobile}
          data={graduatesData.slice(graduatesData.length / 2)}
        />
        <div className="container  mx-auto">
          <div className="mt-8  flex items-center justify-center text-center">
            {' '}
            <LeadGenerationButton buttonLocation={LEAD_GEN_HIRING_SECTION} />
          </div>
        </div>
      </section>

      <section
        className={`mb-0 bg-crio-neutral-100 pt-16 pb-8 ${styles.customFont}`}
      >
        <div className="container">
          <h2 className="mb-12 px-4 text-crio-green-700 md:mx-auto md:px-8 md:pr-0 md:text-center md:text-crio-green-900">
            Developers agree that the best way to learn is by{' '}
            <span className="border-b-4 border-crio-green-700 md:border-crio-green-900">
              doing
            </span>
          </h2>
        </div>
        <div className="mb-12 ">
          <CarouselV3 isMobile={isMobile} data={textualTestimonialsData} />
        </div>
        <div className="mt-4  flex items-center justify-center pb-4 text-center">
          {' '}
          <LeadGenerationButton buttonLocation={LEAD_GEN_TEXTUAL_SECTION} />
        </div>
      </section>

      <section
        className={` mx-auto bg-crio-neutral-75 px-4 pt-16 pb-16 md:px-8 ${styles.customFont}`}
      >
        <div className="container">
          <h2 className="mb-16  text-crio-green-700 md:mx-auto md:pr-0 md:text-center md:text-crio-green-900">
            Stories of developers about their wholesome{' '}
            <span className="border-b-4 border-crio-green-700 md:border-crio-green-900">
              Crio experience
            </span>
          </h2>
          <div className="row">
            {blogTestimonialsData.map((val, idx) => (
              <div className="col-6 col-lg-12 mb-4 p-0" key={idx}>
                <div className="m-2 h-full md:m-3">
                  <CrioExperienceTile isMobile={isMobile} data={val} />{' '}
                </div>
              </div>
            ))}
          </div>{' '}
        </div>
        <div className="mx-auto mt-12 pb-12">
          <a
            href="https://www.crio.do/blog/tag/crio-community/"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              className="mx-auto rounded-md border-2 border-crio-green-900"
              color="#075353"
              onClick={() => {
                captureUserClickEvent('Read all stories');
              }}
              background="#F9F8F7"
              hoverbackground="#075353"
              hovercolor="#F9F8F7"
            >
              Read all stories
            </Button>
          </a>
        </div>
      </section>
      <FooterV2 />
    </>
  );
}

// export default AboutUsPage;

export const pageQuery = graphql`
  query {
    strapiPrograms(PrimaryProgramID: { eq: "FREE_TRIAL" }) {
      ...strapiProgramsFragment
    }
    allStrapiColleges {
      nodes {
        id
        strapiId
        Name
        CollegeLogo {
          localFile {
            childImageSharp {
              gatsbyImageData(
                blurredOptions: {}
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }

    allStrapiTextualTestimonials {
      nodes {
        TestimonialText
        graduate {
          Name
          company
          PhotoVersionTwo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  blurredOptions: {}
                  width: 500
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        id
      }
    }

    allStrapiBlogTestimonials {
      nodes {
        id
        BlogName
        BlogExcerpt
        BlogLink
        graduate {
          Name
          company
          college
        }
        graduate {
          PhotoVersionTwo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  blurredOptions: {}
                  width: 500
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }

    ...strapiDataFragment
  }
`;
