import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import Loader from '@components/Loader/index';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const CarouselV2CodeSplit = loadable(() => import('./CarouselV2CodeSplit'), {
  fallback: (
    <div className="flex h-full  w-full items-center justify-center">
      <Loader></Loader>
    </div>
  ),
});

export default function CarouselV2(props) {
  return (
    <FadeInWhenVisible
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 100 },
      }}
    >
      <div className="mb-10">
        <CarouselV2CodeSplit {...props}></CarouselV2CodeSplit>
      </div>
    </FadeInWhenVisible>
  );
}

function FadeInWhenVisible(props) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [hasComeInViewOnce, setHasComeInViewOnce] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
      setHasComeInViewOnce(true);
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: props.duration ? props.duration : 0.8 }}
      {...props}
      //   exit="hidden"
    >
      {hasComeInViewOnce ? props.children : <Placeholder></Placeholder>}
    </motion.div>
  );
}

function Placeholder() {
  return <div className="h-[175px]"></div>;
}
